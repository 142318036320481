import React from 'react';

import SuccessLayout from '../components/layouts/SuccessLayout';
import ThankYou from '../components/landing-pages/ThankYou';

export default function success() {
  return (
    <>
      <SuccessLayout>
        <ThankYou />
      </SuccessLayout>
    </>
  );
}

import React, { Children } from 'react';
import { ThemeProvider, CSSReset, Flex } from '@chakra-ui/core';

export default function SuccessLayout({ children }) {
  return (
    <>
      <ThemeProvider>
        <CSSReset />
        <Flex
          as="main"
          direction="column"
          minHeight="100vh"
          overflow="scroll"
          bg="gray.100"
        >
          {children}
        </Flex>
      </ThemeProvider>
    </>
  );
}

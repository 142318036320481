import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import {
  Flex,
  Alert,
  AlertIcon,
  AlertTitle,
  Text,
  Box,
  Image,
} from '@chakra-ui/core';

import { capitalizeFirstLetter } from '../../utils/functions';

export default function Success() {
  const location = useLocation();
  const url = location.search;
  const searchParams = queryString.parse(url);
  const userName = searchParams.user_name;

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.clear();
    };

    clearLocalStorage();
  }, []);

  return (
    <Flex w="100%" justifyContent="center">
      <Flex width={{ base: '100%', lg: 1024 }} direction="column" p={4}>
        <Flex as="header" mb={10}>
          <Box w={{ base: '250px', lg: '250px' }}>
            <Image
              src={'/img/EssentialInsuranceLogo.png'}
              alt="Essential insurance logo"
            />
          </Box>
        </Flex>

        <Flex
          bg="white"
          direction="column"
          w={{ base: '100%', md: '500px' }}
          margin="auto"
        >
          <Alert status="success">
            <AlertIcon />
            <AlertTitle mr={2}>Thank you</AlertTitle>
          </Alert>
          <Box mt={4}>
            <Text px={6} pb={6}>
              Thank you {capitalizeFirstLetter(userName)} for taking the time to
              answer my questions. As soon as I have reviewed your circumstances
              and needs, I'll come back to you with your options.
            </Text>
            <Text px={6} pb={6}>
              In the meantime, if you have any questions or you want to speak to
              me in person, please do not hesitate to contact me directly on
              <Text as="a" href="tel:01625466384" fontWeight="bold">
                <Text as="span">&nbsp;01625 466384</Text>
              </Text>
              .
            </Text>
            <Text px={6} pb={6}>
              David Hodson
              <br />
              Protection Advisor
              <br />
              Essential Insurance
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
